<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="rjpp" label="Judul RJPP">
            <a-input v-model:value="formState.rjpp" placeholder="Masukkan judul RJPP" />
          </a-form-item>

          <a-form-item name="description" label="Deskripsi">
            <a-input v-model:value="formState.description" placeholder="Masukkan deskripsi RJPP" />
          </a-form-item>
          <a-row>
            <a-col
              style="margin-bottom:12px;"
              :lg="{ span: 20, offset: 4 }"
              :md="{ span: 15, offset: 9 }"
              :xs="{ span: 24, offset: 0 }"
            >
              <h3>Uraian * [minimal 1 item uraian]</h3>
            </a-col>
          </a-row>
          <a-row>
            <a-col v-for="(uraian, index) in formState.uraians" :key="index" :span="24">
              <a-row v-if="index > 0">
                <a-col
                  style="margin-bottom:12px;"
                  :lg="{ span: 20, offset: 4 }"
                  :md="{ span: 15, offset: 9 }"
                  :xs="{ span: 24, offset: 0 }"
                >
                  <h3>* new item</h3>
                </a-col>
              </a-row>
              <a-form-item name="uraian" label="Uraian">
                <a-input style="width: 85%;" v-model:value="uraian.uraian" placeholder="Masukkan judul uraian" />
                <a-button type="dashed" size="large" style="margin-left:8px;" @click="myFun.hapusItemUraian(index)">
                  Hapus Uraian
                </a-button>
              </a-form-item>
              <a-form-item name="description" label="Deskripsi">
                <a-input v-model:value="uraian.description" placeholder="Masukkan deskripsi uraian" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :lg="{ span: 20, offset: 4 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <a-form-item>
                <a-button type="dashed" style="width: 36%" size="large" @click="myFun.tambahItemUraian()">
                  Tambah Uraian
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row style="margin-bottonm:12px;">
            <a-col :lg="{ span: 20, offset: 4 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/laporan-manajemen/progress-rjpp">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted, toRaw } from 'vue';
import { useRoute } from 'vue-router';
import { notification } from 'ant-design-vue';

const RjppForm = {
  name: 'RjppForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + 'RJPP',
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);

    class Uraian {
      id = null;
      uraian = 'judul uraian ku';
      description;
    }

    const formState = reactive({
      id: null,
      rjpp: '',
      description: '',
      uraians: [new Uraian()],
      remove_uraians: [],
    });

    const labelCol = {
      lg: 4,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 20,
      md: 15,
      xs: 24,
    };

    const handleSubmit = () => {
      let dataParams = toRaw(formState);
      console.log(dataParams);

      if (formState.uraians.length < 1) {
        notification.error({
          message: 'uraian minimal 1 item',
        });
        return;
      }

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'laporan-progress-rjpp',
          data: dataParams,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.itemId,
          url: 'laporan-progress-rjpp',
          data: dataParams,
        });
      }
    };

    const rules = {
      rjpp: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const options = reactive({
      parents: [],
    });

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    // tambah dan hapus uraian
    class Fun {
      tambahItemUraian = () => {
        formState.uraians.push(new Uraian());
      };
      hapusItemUraian = index => {
        if (formState.uraians[index].id != null) {
          formState.remove_uraians.push(formState.uraians[index].id);
        }
        formState.uraians.splice(index, 1);
      };
    }

    const myFun = new Fun();

    onMounted(() => {
      if (props.mode == 'Ubah') {
        const data = {
          url: 'laporan-progress-rjpp',
          id: params.itemId,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          console.log(crud.value);
          formState.id = crud.value.id;
          formState.rjpp = crud.value.rjpp;
          formState.description = crud.value.description;
          formState.uraians = crud.value.uraians;
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      options,
      filterSelect,
      myFun,
    };
  },
};

export default RjppForm;
</script>
